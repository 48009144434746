<template>
  <div
    class="relative min-h-[100dvh] w-full"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">

</script>

